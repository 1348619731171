/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Loading, Image, Pagination } from './components';
import styles from './anexos.module.scss';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick.css';

import content from '../assets/06'

const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, autoplay: true, autoplaySpeed: 3000, pauseOnHover: false, fade: true };
const ImageCarousel = () => (
  <Slider {...settings}>
    <div><img src={content.img01} alt="01" /></div>
    <div><img src={content.img02} alt="02" /></div>
    <div><img src={content.img03} alt="03" /></div>
    <div><img src={content.img04} alt="04" /></div>
    <div><img src={content.img05} alt="05" /></div>
    <div><img src={content.img06} alt="06" /></div>
    <div><img src={content.img07} alt="07" /></div>
    <div><img src={content.img08} alt="08" /></div>
    <div><img src={content.img09} alt="09" /></div>
    <div><img src={content.img10} alt="10" /></div>
    <div><img src={content.img11} alt="11" /></div>
    <div><img src={content.img12} alt="12" /></div>
    <div><img src={content.img13} alt="13" /></div>
    <div><img src={content.img14} alt="14" /></div>
    <div><img src={content.img15} alt="15" /></div>
    <div><img src={content.img16} alt="16" /></div>
    <div><img src={content.img17} alt="17" /></div>
    <div><img src={content.img18} alt="18" /></div>
    <div><img src={content.img19} alt="19" /></div>
    <div><img src={content.img20} alt="20" /></div>
    <div><img src={content.img21} alt="21" /></div>
    <div><img src={content.img22} alt="22" /></div>
    <div><img src={content.img23} alt="23" /></div>
    <div><img src={content.img24} alt="24" /></div>
    <div><img src={content.img25} alt="25" /></div>
    <div><img src={content.img26} alt="26" /></div>
    <div><img src={content.img27} alt="27" /></div>
    <div><img src={content.img28} alt="28" /></div>
    <div><img src={content.img29} alt="29" /></div>
    <div><img src={content.img30} alt="30" /></div>
    <div><img src={content.img31} alt="31" /></div>
    <div><img src={content.img32} alt="32" /></div>
    <div><img src={content.img33} alt="33" /></div>
    <div><img src={content.img34} alt="34" /></div>
    <div><img src={content.img35} alt="35" /></div>
    <div><img src={content.img36} alt="36" /></div>
    <div><img src={content.img37} alt="37" /></div>
    <div><img src={content.img38} alt="38" /></div>
    <div><img src={content.img39} alt="39" /></div>
    <div><img src={content.img40} alt="40" /></div>
    <div><img src={content.img41} alt="41" /></div>
    <div><img src={content.img42} alt="42" /></div>
    <div><img src={content.img43} alt="43" /></div>
    <div><img src={content.img44} alt="44" /></div>
    <div><img src={content.img45} alt="45" /></div>
    <div><img src={content.img46} alt="46" /></div>
    <div><img src={content.img47} alt="47" /></div>
  </Slider>
);

const Anexo06 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSvdLtBRmaujiE71XrSX9uRuBPZ7ICmgN_DgV4kovDYFVjOtTlH5AlpGXH-mMPtKg/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      <Image img={content?.flyer} alt="" />
      <div className={styles.slider}>
        <ImageCarousel />
      </div>
      <Pagination prev="/05" prevText="anterior" next="/07" nextText="siguiente" />
    </div>
  );
}

export default Anexo06;
