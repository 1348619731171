import React from 'react';
import { Link } from 'react-router-dom';
import styles from './intro.module.scss';

const Content = () => (
  <div className={styles.container}>
    <div className={styles.right}>
      <p>Ginebra, Suiza.</p>
    </div>

    <p>Estimados todos y a quién corresponda.</p>

    <p>Miguel Ángel Tello, establecido en Ginebra, Suiza desde hace ya algunos años por motivos familiares.</p>

    <p>Técnico profesional en la administración de empresas turísticas. Con amplia experiencia en el sector, desde operador de viajes, así como en los servicios turísticos y con fuerte relación en Palenque, Chiapas, Tabasco y el mundo maya. Las Constancias se anexan en el <Link to="/01">punto número 1 de anexos.</Link> </p>

    <p>Hace algunos años escribí unas letras dirigidas al señor presidente Andrés Manuel López Obrador y no tuve el atino de hacérselas llegar. Con esperanza que estas lleguen a las personas adecuadas en referencia  y expresarle las peticiones y propuestas que sé apoyarán también a su Gobierno. Se anexa constancia en el <Link to="/02">punto número 2 de anexos.</Link></p>

    <p><b>Desde 1976, mi familia se hizo de un terreno de más de 7 hectáreas en Palenque, Chiapas.</b> Mayormente compuesto de potrero, pasto elefante y acahual. Iniciamos la construcción de una casa en 1980, con la intención inicial de captar semillas y revender; así como poco a poco un sitio para ofrecer servicios turísticos: Restaurant, bar, hotelería, etc.</p>

    <p>Debido a la cercanía con la zona arqueológica, hemos procurado la siembra de árboles y protección del ambiente con sustentabilidad. Se anexan constancias de escrituras públicas, plano y testamentos en el <Link to="/03">punto número 3 y 3B de anexos.</Link></p>

    <p>Decreto expropiatorio promulgado por el expresidente José López Portillo el 20 de julio de 1981. Nos afectó parte del predio e hizo recular en las pretensiones familiares y buscando la solución de indemnización correspondiente a la época. No se logró, administraciones en diferentes tiempos y hasta hoy en día sin solución. Constancias en el <Link to="/04">punto número 4 de anexos.</Link></p>

    <p>Siendo qué, según reza la Ley, son 5 años para concretar el mencionado decreto. Decreto que, de no concretarse, queda en posibilidad de reversión. </p>

    <div className={styles.quote}>
      <b>LEY DE EXPROPIACIÓN</b>
      <p>Artículo 9o.- Si los bienes que han originado una declaratoria de expropiación, ocupación temporal o limitación de dominio no fueren destinados total o parcialmente al fin que dio causa a la declaratoria respectiva, dentro del término de cinco años, el propietario afectado podrá solicitar a la autoridad que haya tramitado el expediente, la reversión total o parcial del bien de que se trate, o la insubsistencia de la ocupación temporal o limitación de dominio, o el pago de los daños causados. Dicha autoridad dictará resolución dentro de los cuarenta y cinco días hábiles siguientes a la presentación de la solicitud. En caso de que se resuelva la reversión total o parcial del bien, el propietario deberá devolver únicamente la totalidad o la parte correspondiente de la indemnización que le hubiere sido cubierta. El derecho que se confiere al propietario en este Artículo, deberá ejercerlo dentro del plazo de dos años, contado a partir de la fecha en que sea exigible.</p>
    </div>

    <p>Agregando que existen anomalías en las coordenadas y delimitaciones del mentado Parque Nacional, por lo que existen vicios en el procedimiento. Recibiendo en septiembre  del 2016, una invitación de la CONANP para integrarnos dentro en su Nuevo Plan de Manejo y realizar el estudio técnico de Modificación a la declaratoria de PN a ANP. Constancias en <Link to="/05">punto número 5 de anexos.</Link></p>

    <p>De una u otra manera, concluimos la casa y algunas otras obras; aunado a un pequeño restaurante denominado “Los Cocos” ya en 1982 contamos con una licencia denominada “Las orquídeas”. Desde esa época, con alientos y desistimientos, nos fuimos sosteniendo en el sitio brindando servicios turísticos, campamentos, palapas, pequeñas cabañas, etc.</p>

    <p>La casa del capitán, los tucanes, el Chaac, varios más y desde año 2000 ya como Elementos Naturales. Sin dejar ser asediados por parte de algunos funcionarios quienes para justificar su empleo, como en su tiempo fueron de la SEDUE y posteriormente con la CONANP. Con más presión después del cobro en la entrada con rondines, inclusive introduciéndose indebidamente dentro de nuestro predio sin previo aviso o autorización escrita para ello .</p>

    <p>Constancias en el <Link to="/06">punto número 6 de anexos.</Link> Copia de algunos permisos, licencias y autorizaciones, OTROS MAS SE ENCUENTRAN DENTRO DEL EXPEDIENTE 249/2000 en el Juzgado de Primera Instancia en Palenque.</p>

    <p><b>Elementos Naturales Investigación AC. Conformada en enero del 2006, con el objeto social que reza dentro de la escritura pública y (CLUNI) ENI06011807016.</b></p>

    <p>En el año 2003, realizamos el primer festival musical en Palenque, con la intención de procurar fondos para la creación del jardín botánico y luego otros festivales. Constancias de imágenes y datos en los puntos <Link to="/07">número 7 de anexos</Link> y <Link to="/08">número 8 de anexos.</Link></p>

    <p>En diciembre del 2006, prácticamente me desprendí físicamente de mí México lindo y querido. Sin embargo, México no está fuera de mí; aunque menguaron algunas actividades no dejo de procurar realizar actividades que conllevarán al objeto social. Nada sencillo con gobiernos diferentes, varias veces visité la Conanp en la ciudad de México y algunas más en la capital del Estado, Tuxtla Gutiérrez con la intención de concretar la autorización de crear la escuela de educación e investigación científica ambiental, sin resultados hasta ahora positivos en su esencia que, es la de una concesión y/o similar qué, nos permita la realización de nuestro objeto social dentro de mí propiedad y son permitidas dentro de la Ley Federal de Equilibrio Ecológico como lo señala el artículo 50.</p>

    <div className={styles.quote}>
      <b>LEY GENERAL DEL EQUILIBRIO ECOLÓGICO Y LA PROTECCIÓN AL AMBIENTE</b>
      <p>ARTÍCULO 50.- Los parques nacionales se constituirán, tratándose de representaciones biogeográficas, a nivel nacional, de uno o más ecosistemas que se signifiquen por su belleza escénica, su valor científico, educativo, de recreo, su valor histórico, por la existencia de flora y fauna, por su aptitud para el desarrollo del turismo, o bien por otras razones análogas de interés general. En los parques nacionales sólo podrá permitirse la realización de actividades relacionadas con la protección de sus recursos naturales, el incremento de su flora y fauna y en general, con la preservación de los ecosistemas y de sus elementos, así como con la investigación, recreación, turismo y educación ecológicos. </p>
    </div>

    <p>Sin dejar de estar atento y pendiente de México, realicé algunas actividades como protagonista del cambio verdadero en favor del movimiento de regeneración nacional Constancias en <Link to="/09">punto número 9 de anexos.</Link></p>

    <p>Esto es un breve acontecer de hechos que me permito intentar resumir y dé muestra que aún estando lejos del país, he seguido y sigo pugnando con intentos y resultados para contribuir de una u otra manera a la protección y desarrollo de nuestro país.</p>

    <p>Nosotros no pretendemos indemnización alguna, ni tampoco lucrar con el Estado y mucho menos del Gobierno, a sabiendas de TODO lo que se requiere para distribuir a los más desfavorecidos. Si bien es cierto que, gracias a los programas sociales y mucho agradezco, mi hermano Saulo, y quién es él que reside dentro del predio es partícipe y beneficiario de Sembrando Vida. Programa ideal para seguir con la protección del sistema ambiental y desarrollo de infinidad de comunidades.</p>

    <p className={styles.underline}>Lo que sólo deseo y solicito es que nos autorice la concesión y/o similar dentro de la Ley  como actividades permitidas dentro del artículo 50 y que es la creación de la escuela de educación ambiental con turismo de bajo impacto y el costo de tinta de la firma de acuerdo.</p>

    <p>La UNESCO lo declaró como patrimonio mundial de la humanidad, y sí,  51 propietarios fuimos afectados. No obstante, el proceso de expropiación no se ejecutó en su totalidad ya que únicamente se pagaron seis predios sin embargo, a la familia y seguramente ninguno de los 46 originarios afectados restantes directos hemos recibido al menos un kilo de frijol, ya ni siquiera con gorgojo, nada, y pienso que así seguirá siendo ¡Vaya!</p>

    <p>Con la autorización, ya nos iremos adaptando poco a poco a como nuestros esfuerzos y medios económicos nos lo permitan, la creación de salones, aulas y salas con los servicios adecuados para las actividades propias en las enseñanzas educativas ambientales.</p>

    <p>Áreas destinadas para juegos educativos ambientales, deportivos y jardín botánico. Buscando la donación de algún vehículo tipo camión que nos permita ir a cada colegio de la región, salón por salón llevar a los alumnos al sitio y compartir actividades de concientización a los alumnos y más, Un día un grupo, otro día uno más, etc. Posteriormente otro colegio de la región, ofreciéndoles además e idealmente desayunos nutritivos dentro de la visita y formación.</p>

    <p>Actividades que hemos realizado, pese el acoso de algunos servidores públicos, como lo muestran las imágenes dentro de las <Link to="/10">punto número 10 de anexos.</Link></p>

    <p>Sitios de pernocta para colaboradores, personal docente e invitados especiales de formadores relevantes en la educación ambiental.</p>

    <p>Para no depender del Estado, la realización de cabañas destinadas al turismo de bajo impacto con la intención del autofinanciamiento del sitio con todo lo que se requiera y sea autorizados por las Instituciones a su cargo y ser apoyados en el tipo de materiales en su creación, como por ejemplo los materiales básicos pensados para la construcción serán de Bambú y energía combinada solar. Sitio que se pretende sea un modelo a replicar en diferentes sitios de la ruta maya y demás. Desde 2006 existe un modelo similar en Bali, Indonesia, denominado “Green School. Ideal para replicar en diferentes sitios adaptándose a nuestras culturas, zonas geográficas, etc. Comparto parte de ello como <Link to="/11">punto número 11 de anexos.</Link></p>

    <p>Deseo pasar mis últimos días en mí bello Palenque, ahí mismo, dónde pasé largos años de mi vida y qué mejor ver concretado o bien avanzando en un legado para las futuras generaciones en la educación, protección y concientización ambiental.</p>

    <p><b>Algo de historia y propuestas:</b></p>

    <p>La Chontalpa, con toda la familia en los años 1969-1970 y debido a una plaga a la pimienta de otros países en los años 1983-84 y algo más, estuve recorriendo los caminos, poblaciones, ejidos, rancherías, brechas y demás pregonando la compra de pimienta, conociendo la gente, sus formas de vida, etc. Casi todos los municipios se visitaban y en diferentes periodos. Don leches en Jalpa, doña esa, los somellets, y así infinidad de anécdotas.</p>

    <p>Mi abuela, originaria de la Perla de la Chontalpa, así como algunos primos y familiares que residen en Comalcalco, hemos pasado momentos muy gratos y otros zamarreado por los piquetes del chaquiste.</p>

    <p>En Suiza desde 2008, después de largos  meses para obtener los permisos correspondientes, me empleé como chofer distribuidor de materiales, algunos bastante pesados y recorriendo  un mínimo de 500 kilómetros diarios, con pesos entre 2000 y 3000 kilos por día entre cargas y descargas. Desde abril del año 2023 estoy en paro médico con problemas de articulaciones y otros malestares. O sea, no cuento con recursos para grandes inversiones; sin embargo, siento que tengo la capacidad de persuadir para ir creando los objetivos y metas, una vez que se dé vía libre bajo su supervisión.</p>

    <p>TJ o Turismo Juvenil SA. 1996, contribuimos en la creación de albergues juveniles del mundo maya, un programa federal, lamentablemente algunos funcionarios al parecer aplicaron el “toma todo” y zas, ahí quedó el apoyo en parte para la publicidad del programa.</p>

    <p>Sí se avanzó con ellos y creamos diferentes rutas y circuitos turísticos a muy bajo costo rentando las camas en literas y no toda la habitación, similar a como lo era él CREA en Cancún.</p>

    <p>Bajo ese concepto, conformamos VT o Viajes y Tours SA, 1997 independientes totalmente del estado, pero con los contactos creados nacionales e internacionales, seguimos creando rutas y dando servicios de viajes y circuitos turísticos, grupos y convenciones. Participando en ferias, tianguis turísticos, nacionales e internacionales.</p>

    <p>En la agencia operadora de viajes, conocí a la madre de mi hijo y al tiempo nos casamos, decidimos crear una familia y ya con ese plan y otras situaciones no muy agradables cerramos la agencia y nos movimos a Palenque un tiempo para terminar de dar servicio algunos grupos de clientes ya creados con eventos anuales, terminando los mismos, partimos a Suiza.</p>

    <p>Palenque después del año 2000, desacuerdos de familia, ocasionaron que mi vida se repartiera entre Ginebra y Palenque, donde realicé el Primer festival musical 2003, con los  objetivos de fomentar la cultura, creando conciencia ecológica a través de la música y captar fondos para realizar un jardín botánico, ese mismo año intenté 3 y logré realizar 2 (anexo) carteles. Todos con patrocinios de diferentes empresas, amigos, gente del pueblo y lugares aledaños, músicos y artistas por amor a la causa. Yo procuraba las condiciones y algo se podían llevar como amigos que lo son y en solidaridad. Estos festivales los realicé sin medios económicos propios, no porque no quisiera aportar, simplemente porque no contaba con dinero propio. Todo con la fuerza de la voluntad y la palabra cargada siempre de emoción.</p>

    <p>Ya en 2006, hice otro festival denominado de regreso en casa, por la problemática familiar tuvimos que desocupar el predio y en 2002 y solo nos prestaron el terreno para hacer el Primer Festival en Palenque 2003, otro con el finado Miguel Padilla. Ya de regreso en casa 2005, con muchos esfuerzos se recuperó el predio y en parte lo volvimos a levantar; aunque ya no tan sencillo con todo lo perdido; sin embargo, ahí iba funcionando con todas las trabas y asedios que imponía e impone la Conanp. Anexo imágenes del cómo dejé el sitio antes de mí partida en diciembre del 2006 y algunas del estado en que se encuentra el sitio en 2025. Se han logrado realizar algunas otras actividades relacionadas a la educación y concientización ambiental, aunque muy poco para las que se requieren.</p>

    <p>En el año 2019, logré el certificado federal de cafeteros para poder vender productos alimenticios preparados en Suiza, con derecho a montar cualquier tipo de establecimiento relacionado al servicio, restaurantes, bar, hotel, Comercializadora de proyectos y realización de eventos grupos y convenciones en Suiza.</p>

    <p>No todos los suizos o franceses lo logran, yo sí. </p>

    <p>Lo que me hace ya dudar un poco es mí edad ya voy por los 61 y si bien puedo dirigir asesorando; sin embargo, ya meterme yo a la talacha, ya no me siento con la fuerza necesaria para largo plazo, conozco y como ejemplos, preparo: chanchamitos, horneado, cochito, panuchos, platanitos rellenos, penchuques, etc.</p>

    <p>Constancias de diplomas obtenidos en Suiza y los anexo en el <Link to="/12">punto número 12 de anexos.</Link></p>

    <p><b>Problemáticas y soluciones en desechos.</b></p>

    <p>No pienso que solo sembrar y beneficiarse sea la mejor solución, ayuda sí, solo que se debe ir más a fondo, cómo lograr que no se piense en destruir, que eso no pase por la cabeza del humano, sino la convivencia y respeto diario, día a día, acción y acción cotidiana. que las denominaciones de Áreas Naturales Protegidas, sean de simple nombre, porque trabajando en conjunto en la educación y concientización, ya las ANP quedarán enmarcadas como símbolo, porque en la hechos su protección y cuidados serán por cultura general de las poblaciones y los gobiernos vigilantes que las empresas proveedores de insumos donen los contenedores y participen en su recolección y acercamientos a los vagones y estos a las plantas recicladores.</p>

    <p>Sitios de desechos y su forma de separar en depósitos independientes, y la pregunta de los 64,000 es quienes recogerán los mismos y no resulte que un sólo camión recoge desechos separados y como no hay otro camión, todo sea llevado al mismo sitio para desechar en algún hoyo.</p>

    <p>La problemática en la región del Sur de Veracruz: Minatitlán, Coatzacoalcos, Acayucan, etc.</p>

    <p>No pienso que sea del todo ideal haber encontrado un terreno para rellenar, sino un sitio y  varios más para tratar los reciclables, lo que es mucho más efectivo e incremento de fuentes de trabajo e ingresos para las poblaciones. Esas plantas de reciclaje, deben de replicarse por el país. No sirve de mucho separar, si al final todo se va al mismo hoyo.</p>

    <p>Esto considero le ayudaría mucho.</p>

    <p>Tren Maya, uno o dos vagones, o quizás un tren asignado a recolectar los materiales que deben destinarse al transporte de desechos para reciclar en las plantas estratégicamente funcionales e ubicadas.</p>

    <p>No sólo se trata de no tirar, ni de separar; sino de que los desechos sean tratados y reciclados. Lo cual el inminente incremento de insumos y desechos que ocasionará el paso del tren, vayan acompañado de sitios para su recolección y tratamientos adecuados. Las plantas recicladoras, de no ser del todo rentables, una parte del costo del boleto del tren, bien puede asignarse al subsidio de las mismas.</p>

    <p><b>Centro de Cultura para la Conservación. C.C.C.</b></p>

    <p>Desde hace años hemos intentado crear un espacio similar de manera autorizada (anexo solicitud 2009), en Palenque. Con un gran gusto he observado que la Conanp, ha realizado ya una en el kilómetro 6.5 rumbo a la zona arqueológica de Palenque. Eso es un muy buen principio. Solo que es administrado por el Estado y lo que desde acá se observa en imágenes, no percibo lo contundente y la afectividad que sé se requiere. Es sólo llenar el requisito y su justificación a los ingresos que perciben, más no por convicción, como sí lo es en nuestro caso. Constancias en el <Link to="/13">punto número 13 de anexos.</Link></p>

    <p>Es positivo que ya exista algo, solo que se requerirán muchas más acciones, coadyuvando con ideas propias y ajenas en pro del medioambiente. Constancias en copia de la solicitud en <Link to="/14">punto número 14 de anexos.</Link></p>

    <p>Lo que se propone es algo mucho más a fondo y permeable en la concientización de la población. </p>

    <br/>

    <p><b>Contexto de los Anexos.</b></p>

    <ol className={styles.list}>
      <li><Link to="/01">1. Constancias de Turismo Juvenil TJ y Viajes & Tours VT</Link></li>
      <li><Link to="/02">2. Carta AMLO 2019</Link></li>
      <li><Link to="/03">3. Constancias de Documentos, Escrituras, Poderes y Testamentos</Link></li>
      <li><Link to="/04">4. Constancias de solicitudes de indemnización</Link></li>
      <li><Link to="/05">5. Constancias de errores en los polígonos del Parque Nacional de Palenque</Link></li>
      <li><Link to="/06">6. Constancias de actividades realizadas y autorizaciones</Link></li>
      <li><Link to="/07">7. Constancias de Acta Constitutiva Elementos Naturales Investigación AC</Link></li>
      <li><Link to="/08">8. Constancias de los Festivales musicales EN Palenque, Chiapas</Link></li>
      <li><Link to="/09">9. Constancias de imágenes y videos de Morena Internacional (Tren y defensa del petróleo)</Link></li>
      <li><Link to="/10">10. Constancias de algunas actividades educativas ambientales, deportivas, culinarias dentro del predio</Link></li>
      <li><Link to="/11">11. Parte de las propuestas para Elementos Naturales Investigación AC. Green School en Bali</Link></li>
      <li><Link to="/12">12. Constancias de diplomas y propuestas en Suiza</Link></li>
      <li><Link to="/13">13. Constancias de Participaciones con el Municipio y propuestas</Link></li>
      <li><Link to="/14">14. Constancias de solicitudes y acoso de algunas instituciones</Link></li>
    </ol>

    <div className={styles.end}>
      <p>Miguel Ángel Tello Romero</p>
      <p className={styles.blue}>tello64@hotmail.com</p>
    </div>
  </div>
);

const Intro = () => {
  return (
    <div className={styles.container}>
      <Content />
    </div>
  );
}

export default Intro;
