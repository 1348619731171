import img01 from './01.jpg';
import img02 from './02.jpg';
import img03 from './03.jpg';
import img04 from './04.jpg';
import img05 from './05.jpg';
import img06 from './06.jpg';
import img07 from './07.jpg';
import img08 from './08.jpg';
import img09 from './09.jpg';
import img10 from './10.jpg';
import img11 from './11.jpg';
import img12 from './12.jpg';
import img13 from './13.jpg';
import img14 from './14.jpg';
import img15 from './15.jpg';
import img16 from './16.jpg';
import img17 from './17.jpg';
import img18 from './18.jpg';
import img19 from './19.jpg';
import img20 from './20.jpg';
import img21 from './21.jpg';
import img22 from './22.jpg';
import img23 from './23.jpg';
import img24 from './24.jpg';
import img25 from './25.jpg';
import img26 from './26.jpg';
import img27 from './27.jpg';
import img28 from './28.jpg';
import img29 from './29.jpg';
import img30 from './30.jpg';
import img31 from './31.jpg';
import img32 from './32.jpg';
import img33 from './33.jpg';
import img34 from './34.jpg';
import img35 from './35.jpg';
import img36 from './36.jpg';
import img37 from './37.jpg';
import img38 from './38.jpg';
import img39 from './39.jpg';
import img40 from './40.jpg';
import img41 from './41.jpg';
import img42 from './42.jpg';
import img43 from './43.jpg';
import img44 from './44.jpg';
import img45 from './45.jpg';
import img46 from './46.jpg';
import img47 from './47.jpg';
import flyer from './EN-flyer.jpg';


const content = {
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  flyer,
};

export default content;
