import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

const Menu = ({ isOpen, setOpen, isDesktop }) => {
  const clickAction = () => {
    setOpen(false)
    window.scrollTo(0, 0);
  };
  return (
    <div className={`${styles.container} ${!isDesktop && isOpen ? styles.containerOpen : ''}`}>
      <div className={styles.content}>
        <NavLink to="/" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>Introducción</NavLink>
        <p>ANEXOS</p>
        <NavLink to="/01" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>1.</span>
          <span>Constancias de Turismo Juvenil TJ y Viajes & Tours VT</span></NavLink>
        <NavLink to="/02" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>2.</span>
          <span>Carta AMLO 2019</span>
        </NavLink>
        <NavLink to="/03" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>3.</span>
          <span>Constancias  Documentos Escrituras y Poderes</span>
        </NavLink>
        <NavLink to="/04" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>4.</span>
          <span>Constancias de solicitudes de indemnización</span>
        </NavLink>
        <NavLink to="/05" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>5.</span>
          <span>Constancias de errores en los polígonos del Parque Nacional de Palenque</span>
        </NavLink>
        <NavLink to="/06" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>6.</span>
          <span>Constancias de actividades realizadas y autorizaciones</span>
        </NavLink>
        <NavLink to="/07" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>7.</span>
          <span>Constancias de Acta Constitutiva Elementos Naturales Investigación AC</span>
        </NavLink>
        <NavLink to="/08" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>8.</span>
          <span>Constancias de los Festivales musicales EN Palenque, Chiapas</span>
        </NavLink>
        <NavLink to="/09" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>9.</span>
          <span>Constancias de imágenes y videos de Morena Internacional (Tren y defensa del petróleo)</span>
        </NavLink>
        <NavLink to="/10" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>10.</span>
          <span>Constancias de algunas actividades educativas ambientales, deportivas, culinarias dentro del predio</span>
        </NavLink>
        <NavLink to="/11" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>11.</span>
          <span>Parte de las propuestas para Elementos Naturales Investigación AC. Green School en Bali</span>
        </NavLink>
        <NavLink to="/12" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>12.</span>
          <span>Constancias de diplomas y propuestas en Suiza</span>
        </NavLink>
        <NavLink to="/13" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>13.</span>
          <span>Constancias de Participaciones con el Municipio y propuestas</span>
        </NavLink>
        <NavLink to="/14" className={({ isActive }) => isActive ? styles.activeLink : ''} onClick={clickAction}>
          <span>14.</span>
          <span>Constancias de solicitudes y acoso de algunas instituciones</span>
        </NavLink>
      </div>
    </div>
  )
}

export default Menu;
